import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { loggedInUserAtom } from "../atom/loggedInUserAtom";

export const useAdminCheck = () => {
  const navigate = useNavigate();
  const loggedInUser = useRecoilValue(loggedInUserAtom);

  useEffect(() => {
    if (loggedInUser == null || !loggedInUser.isAdmin) {
      const securePathId = loggedInUser?.organization?.securePathId
      const loginPath = securePathId ? `/${securePathId}/login` : '/login'
      navigate(loginPath);
    }
  }, [loggedInUser, navigate]);
}