import type { ChangeEventHandler, FocusEventHandler } from "react";
import { SerializedStyles } from "@emotion/react";

export type TextAreaProps = {
  error?: string;
  className?: string;
  placeholder?: string;
  css: SerializedStyles;
  errCss: SerializedStyles;
  rows: number;
};

export const TextArea = (
  props: TextAreaProps & {
    inputRef: React.LegacyRef<HTMLTextAreaElement>;
    value: string;
    onChange: ChangeEventHandler<HTMLTextAreaElement>;
    onBlur: FocusEventHandler<HTMLTextAreaElement>;
  }
) => {
  return (
    <>
      <textarea
        rows={props.rows}
        placeholder={props.placeholder}
        className={props.className}
        ref={props.inputRef}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
      <div css={props.errCss}> {!!props.error && props.error} </div>
    </>
  );
};
