import type { ChangeEventHandler, FocusEventHandler, HTMLInputTypeAttribute } from "react";
import { SerializedStyles } from "@emotion/react";

export type InputProps = {
  error?: string;
  className?: string;
  placeholder?: string;
  css: SerializedStyles;
  errCss: SerializedStyles;
  type: HTMLInputTypeAttribute;
};

export const Input = (
  props: InputProps & {
    inputRef: React.LegacyRef<HTMLInputElement>;
    value: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    onBlur: FocusEventHandler<HTMLInputElement>;
  }
) => {
  return (
    <>
      <input
        type={props.type}
        placeholder={props.placeholder}
        className={props.className}
        ref={props.inputRef}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        defaultValue=""
      />
      <div css={props.errCss}> {!!props.error && props.error} </div>
    </>
  );
};
