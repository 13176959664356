import type {
  ChangeEventHandler,
  FocusEventHandler,
} from "react";
import { SerializedStyles } from "@emotion/react";

export type CheckBoxProps = {
  error?: string;
  className?: string;
  placeholder?: string;
  css: SerializedStyles;
  errCss: SerializedStyles
  labels: { value: string; name: string };
};

export const CheckBox = (
  props: CheckBoxProps & {
    inputRef: React.LegacyRef<HTMLInputElement>;
    onChange: ChangeEventHandler<HTMLInputElement>;
    onBlur: FocusEventHandler<HTMLInputElement>;
  }
) => {
  return (
    <>
      <label>
        <input
          defaultChecked={false}
          type="checkbox"
          placeholder={props.placeholder}
          className={props.className}
          ref={props.inputRef}
          value={props.labels.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
        />
        {props.labels.name}
      </label>
      <div css={props.errCss}> {!!props.error && props.error} </div>
    </>
  );
};
