import {
  ClientCompany,
  ContractAttribution,
  ContractCondition,
  ContractVehicle,
  ParkingContractor,
  ParkingUser,
  ContractDetail,
  PaymentUpdate,
  Lessor,
  BrokerCompany,
  Organization
} from "./forDirect/importDirectModel";
import { api } from "../../../infra/Api";
import { HttpStatus } from "../../../models/HttpStatus";
import { CsvColumn, CsvLocalizedColumn, CsvModel, ImportContractCsvResponse, LocalizedValueLabels } from './forDirect/types';
import { convert_normal_contract, convert_contract_kind, convert_management_state, convert_partition_type, convert_payment_method, convert_payment_deposit_kind_type, convert_auto_update, convert_rental_item, convert_initial_procedure_type, convert_tax_category, convert_seasonal_adjustment, convert_lessor_type } from "./forDirect/dict";

type ErrorResponse = {
  [key: string]: any;
}

const getCsvNumberValue = (csv: CsvModel, key: CsvColumn) => {
    const value = csv[localizedKey(key)]
    return value !== undefined && value.length > 0 ? +value : undefined
}

const getCsvBooleanValue = (csv: CsvModel, key: CsvColumn) => {
  const value = csv[localizedKey(key)]
  return value !== undefined && value.length > 0 ? value.toUpperCase() === 'TRUE' : undefined
}

const undefinedIfEmpty = (value: string) => value?.length === 0 ? undefined : value;

const convert = (csv: CsvModel): ContractDetail => {
  const client_company: ClientCompany = {
    client_company_name: csv[localizedKey('client_company.client_company_name')],
    client_store_name: csv[localizedKey('client_company.client_store_name')],
    parking_name: csv[localizedKey('client_company.parking_name')],
    partition: csv[localizedKey('client_company.partition')],
    partition_type: convert_partition_type(csv[localizedKey('client_company.partition_type')]),
    address: csv[localizedKey('client_company.address')],
    latitude: getCsvNumberValue(csv, 'client_company.latitude'),
    longitude: getCsvNumberValue(csv, 'client_company.longitude'),
    client_company_postal_code: csv[localizedKey('client_company.client_company_postal_code')],
    client_company_address: csv[localizedKey('client_company.client_company_address')],
    phone_number: csv[localizedKey('client_company.phone_number')],
    fax_number: csv[localizedKey('client_company.fax_number')],
    staff_name: csv[localizedKey('client_company.staff_name')],
    staff_email: csv[localizedKey('client_company.staff_email')],
    invoice_name_or_number: csv[localizedKey('client_company.invoice_name_or_number')],
    invoice_tax_rate: getCsvNumberValue(csv, 'client_company.invoice_tax_rate'),
    invoice_receiver_name: csv[localizedKey('client_company.invoice_receiver_name')],
    guarantor_company_name: csv[localizedKey('client_company.guarantor_company_name')],
    guarantor_invoice_number: csv[localizedKey('client_company.guarantor_invoice_number')],
    note: csv[localizedKey('client_company.note')],
  };

  const lessor: Lessor = {
    lessor_name: csv[localizedKey('lessor.lessor_name')],
    lessor_type: convert_lessor_type(csv[localizedKey('lessor.lessor_type')]),
    postal_code: csv[localizedKey('lessor.postal_code')],
    address: csv[localizedKey('lessor.address')],
    phone_number: csv[localizedKey('lessor.phone_number')],
    fax_number: csv[localizedKey('lessor.fax_number')],
    email: csv[localizedKey('lessor.email')],
    invoice_name_or_number: csv[localizedKey('lessor.invoice_name_or_number')],
    invoice_tax_rate: getCsvNumberValue(csv, 'lessor.invoice_tax_rate'),
    invoice_receiver_name: csv[localizedKey('lessor.invoice_receiver_name')],
    note: csv[localizedKey('lessor.note')],
  }

  const broker_company: BrokerCompany = {
    broker_company_name: csv[localizedKey('broker_company.broker_company_name')],
    postal_code: csv[localizedKey('broker_company.postal_code')],
    address: csv[localizedKey('broker_company.address')],
    phone_number: csv[localizedKey('broker_company.phone_number')],
    fax_number: csv[localizedKey('broker_company.fax_number')],
    email: csv[localizedKey('broker_company.email')],
    invoice_name_or_number: csv[localizedKey('broker_company.invoice_name_or_number')],
    invoice_tax_rate: getCsvNumberValue(csv, 'broker_company.invoice_tax_rate'),
    invoice_receiver_name: csv[localizedKey('broker_company.invoice_receiver_name')],
    note: csv[localizedKey('broker_company.note')],
  }

  const contract_attribution: ContractAttribution = {
    memo1: csv[localizedKey('contract_attribution.memo1')],
    memo2: csv[localizedKey('contract_attribution.memo2')],
    memo3: csv[localizedKey('contract_attribution.memo3')],
    other1: csv[localizedKey('contract_attribution.other1')],
    other1_memo: csv[localizedKey('contract_attribution.other1_memo')],
    other2: csv[localizedKey('contract_attribution.other2')],
    other2_memo: csv[localizedKey('contract_attribution.other2_memo')],
    other3: csv[localizedKey('contract_attribution.other3')],
    other3_memo: csv[localizedKey('contract_attribution.other3_memo')],
    usage: csv[localizedKey('contract_attribution.usage')],
    notice_memo1: csv[localizedKey('contract_attribution.notice_memo1')],
    notice_memo2: csv[localizedKey('contract_attribution.notice_memo2')],
    notice_memo3: csv[localizedKey('contract_attribution.notice_memo3')],
    init_payment_company_name: csv[localizedKey('contract_attribution.init_payment_company_name')],
    init_payment_send_money_date: undefinedIfEmpty(csv[localizedKey('contract_attribution.init_payment_send_money_date')]),
    init_payment_bank_number: csv[localizedKey('contract_attribution.init_payment_bank_number')],
    init_payment_bank_name: csv[localizedKey('contract_attribution.init_payment_bank_name')],
    init_payment_branch_store_number: csv[localizedKey('contract_attribution.init_payment_branch_store_number')],
    init_payment_branch_name: csv[localizedKey('contract_attribution.init_payment_branch_name')],
    init_payment_deposit_kind_type: convert_payment_deposit_kind_type(csv[localizedKey('contract_attribution.init_payment_deposit_kind_type')]),
    init_payment_bank_account_number: csv[localizedKey('contract_attribution.init_payment_bank_account_number')],
    init_payment_bank_account_name: csv[localizedKey('contract_attribution.init_payment_bank_account_name')],
    init_payment_send_money_amount: getCsvNumberValue(csv, 'contract_attribution.init_payment_send_money_amount'),
    monthly_payment_company_name: csv[localizedKey('contract_attribution.monthly_payment_company_name')],
    monthly_payment_send_money_date: undefinedIfEmpty(csv[localizedKey('contract_attribution.monthly_payment_send_money_date')]),
    monthly_payment_bank_number: csv[localizedKey('contract_attribution.monthly_payment_bank_number')],
    monthly_payment_bank_name: csv[localizedKey('contract_attribution.monthly_payment_bank_name')],
    monthly_payment_branch_store_number: csv[localizedKey('contract_attribution.monthly_payment_branch_store_number')],
    monthly_payment_branch_name: csv[localizedKey('contract_attribution.monthly_payment_branch_name')],
    monthly_payment_deposit_kind_type: convert_payment_deposit_kind_type(csv[localizedKey('contract_attribution.monthly_payment_deposit_kind_type')]),
    monthly_payment_bank_account_number: csv[localizedKey('contract_attribution.monthly_payment_bank_account_number')],
    monthly_payment_bank_account_name: csv[localizedKey('contract_attribution.monthly_payment_bank_account_name')],
    monthly_payment_send_money_amount: getCsvNumberValue(csv, 'contract_attribution.monthly_payment_send_money_amount'),
  };

  const contract_condition: ContractCondition = {
    tax_category: convert_tax_category(csv[localizedKey('contract_condition.tax_category')]),
    administrative_fee_with_tax: getCsvNumberValue(csv, 'contract_condition.administrative_fee_with_tax'),
    brokerage_fee_with_tax: getCsvNumberValue(csv, 'contract_condition.brokerage_fee_with_tax'),
    cancel_date: undefinedIfEmpty(csv[localizedKey('contract_condition.cancel_date')]),
    contract_period_month: getCsvNumberValue(csv, 'contract_condition.contract_period_month'),
    contracted_date: undefinedIfEmpty(csv[localizedKey('contract_condition.contracted_date')]),
    deposit_with_tax: getCsvNumberValue(csv, 'contract_condition.deposit_with_tax'),
    desired_cancellation_date: undefinedIfEmpty(csv[localizedKey('contract_condition.desired_cancellation_date')]),
    end_date: undefinedIfEmpty(csv[localizedKey('contract_condition.end_date')]),
    first_start_date: undefinedIfEmpty(csv[localizedKey('contract_condition.first_start_date')]),
    initial_guarantee_and_management_fee_with_tax: getCsvNumberValue(csv, 'contract_condition.initial_guarantee_and_management_fee_with_tax'),
    issue_paper_fee_with_tax: getCsvNumberValue(csv, 'contract_condition.issue_paper_fee_with_tax'),
    key_money_with_tax: getCsvNumberValue(csv, 'contract_condition.key_money_with_tax'),
    rent_without_tax: getCsvNumberValue(csv, 'contract_condition.rent_without_tax'),
    monthly_guarantee_and_management_fee_with_tax: getCsvNumberValue(csv, 'contract_condition.monthly_guarantee_and_management_fee_with_tax'),
    need_issue_paper: getCsvBooleanValue(csv, 'contract_condition.need_issue_paper'),
    issue_date: undefinedIfEmpty(csv[localizedKey('contract_condition.issue_date')]),
    payment_date: undefinedIfEmpty(csv[localizedKey('contract_condition.payment_date')]),
    initial_payment_date: undefinedIfEmpty(csv[localizedKey('contract_condition.initial_payment_date')]),
    payment_interval: undefined,
    payment_method: convert_payment_method(csv[localizedKey('contract_condition.payment_method')]) ?? undefined,
    rent_with_tax: getCsvNumberValue(csv, 'contract_condition.rent_with_tax'),
    start_date: undefinedIfEmpty(csv[localizedKey('contract_condition.start_date')]),
    update_base_fee_with_tax: getCsvNumberValue(csv, 'contract_condition.update_base_fee_with_tax'),
    update_management_fee_with_tax: getCsvNumberValue(csv, 'contract_condition.update_management_fee_with_tax'),
    renewal_management_fee_with_tax: getCsvNumberValue(csv, 'contract_condition.renewal_management_fee_with_tax'),
    first_month_rent_with_tax: getCsvNumberValue(csv, 'contract_condition.first_month_rent_with_tax'),
    final_month_rent_with_tax: getCsvNumberValue(csv, 'contract_condition.final_month_rent_with_tax'),
    first_month_rent_without_tax: getCsvNumberValue(csv, 'contract_condition.first_month_rent_without_tax'),
    final_month_rent_without_tax: getCsvNumberValue(csv, 'contract_condition.final_month_rent_without_tax'),
    final_month_guarantee_and_management_fee_with_tax: getCsvNumberValue(csv, 'contract_condition.final_month_guarantee_and_management_fee_with_tax'),
    first_month_guarantee_and_management_fee_with_tax: getCsvNumberValue(csv, 'contract_condition.first_month_guarantee_and_management_fee_with_tax'),
    seasonal_adjustment: convert_seasonal_adjustment(csv[localizedKey('contract_condition.seasonal_adjustment')]),
    seasonal_rent_from: getCsvNumberValue(csv, 'contract_condition.seasonal_rent_from'),
    seasonal_rent_to: getCsvNumberValue(csv, 'contract_condition.seasonal_rent_to'),
    seasonal_fee_with_tax: getCsvNumberValue(csv, 'contract_condition.seasonal_fee_with_tax'),
    prorated_rent_refund: getCsvNumberValue(csv, 'contract_condition.prorated_rent_refund'),
    security_deposit_refund: getCsvNumberValue(csv, 'contract_condition.security_deposit_refund'),
    initial_guarantee_fee_without_tax: getCsvNumberValue(csv, 'contract_condition.initial_guarantee_fee_without_tax'),
    monthly_guarantee_fee_without_tax: getCsvNumberValue(csv, 'contract_condition.monthly_guarantee_fee_without_tax'),
  };

  const contract_vehicle: ContractVehicle = {
    car_model: csv[localizedKey('contract_vehicle.car_model')],
    certificate: undefined,
    certificate_path: undefined,
    regno_class_digit: csv[localizedKey('contract_vehicle.regno_class_digit')],
    regno_hiragana: csv[localizedKey('contract_vehicle.regno_hiragana')],
    regno_region_name: csv[localizedKey('contract_vehicle.regno_region_name')],
    regno_seq_digit: csv[localizedKey('contract_vehicle.regno_seq_digit')],
  };

  const parking_contractor: ParkingContractor = {
    affiliation_branch_name: csv[localizedKey('parking_contractor.affiliation_branch_name')],
    affiliation_department_name: csv[localizedKey('parking_contractor.affiliation_department_name')],
    affiliation_branch_number: csv[localizedKey('parking_contractor.affiliation_branch_number')],
    affiliation_department_number: csv[localizedKey('parking_contractor.affiliation_department_number')],
    email: csv[localizedKey('parking_contractor.email')],
    employee_number: csv[localizedKey('parking_contractor.employee_number')],
    name: csv[localizedKey('parking_contractor.name')],
    name_kana: csv[localizedKey('parking_contractor.name_kana')],
    manager: csv[localizedKey('parking_contractor.manager')],
    phone_number: csv[localizedKey('parking_contractor.phone_number')],
    company_phone_number: csv[localizedKey('parking_contractor.company_phone_number')],
  };

  const parking_user: ParkingUser = {
    affiliation_branch_name: csv[localizedKey('parking_user.affiliation_branch_name')],
    affiliation_department_name: csv[localizedKey('parking_user.affiliation_department_name')],
    affiliation_branch_number: csv[localizedKey('parking_user.affiliation_branch_number')],
    affiliation_department_number: csv[localizedKey('parking_user.affiliation_department_number')],
    employee_number: csv[localizedKey('parking_user.employee_number')],
    mobile_phone_number: csv[localizedKey('parking_user.mobile_phone_number')],
    name: csv[localizedKey('parking_user.name')],
    name_kana: csv[localizedKey('parking_user.name_kana')],
    manager: csv[localizedKey('parking_user.manager')],
    phone_number: csv[localizedKey('parking_user.phone_number')],
    email: csv[localizedKey('parking_user.email')],
  };

  const organization: Organization | undefined = csv[localizedKey('organization.id')] ? {
    id: csv[localizedKey('organization.id')],
    name: csv[localizedKey('organization.name')],
    note: undefined,
    entry_pd_form_url: undefined,
    entry_pd_agency_form_url: undefined,
    entry_direct_form_url: undefined,
    update_contract_form_url: undefined,
    cancel_pd_contract_form_url: undefined,
    cancel_direct_contract_form_url: undefined,
    notifications_url: undefined,
    secure_path_id: undefined,
  } : undefined

  const for_payment_update: PaymentUpdate = {
    for_payment_update_first_month: getCsvNumberValue(csv, 'for_payment_update_first_month'),
    for_payment_update_normal_month: getCsvNumberValue(csv, 'for_payment_update_normal_month'),
    for_payment_update_cancel_month: getCsvNumberValue(csv, 'for_payment_update_cancel_month'),
  }

  const contract: ContractDetail & { for_payment_update: PaymentUpdate } = {
    id: csv[localizedKey('id')],
    contract_kind: convert_contract_kind(csv[localizedKey('contract_kind')]) ?? undefined,
    auto_update: convert_auto_update(csv[localizedKey('auto_update')]) ?? undefined,
    rental_item: convert_rental_item(csv[localizedKey('rental_item')]) ?? undefined,
    rental_item_contents: csv[localizedKey('rental_item_contents')],
    management_state: convert_management_state(csv[localizedKey('management_state')]) ?? undefined,
    organization,
    client_company,
    lessor,
    broker_company,
    contract_attribution,
    contract_condition,
    contract_vehicle,
    parking_contractor,
    parking_user,
    payment_bank: csv[localizedKey('payment_bank')],
    normal_contract: convert_normal_contract(csv[localizedKey('normal_contract')]) ?? undefined,
    customer_number: undefined,
    lease_agreement: undefined,
    lease_agreement_path: undefined,
    for_payment_update,
    initial_procedure_type: convert_initial_procedure_type(csv[localizedKey('initial_procedure_type')])
  };

  return contract;
};

const create = async (contract: Partial<ContractDetail>) => {
  const [http, newContract] = await api.post<Partial<ContractDetail>, ContractDetail>(
    "/api/v1/admin/contract_list/",
    contract,
  );
  return { http, newContract };
};

export const importContractCsv = async (csv: CsvModel): Promise<ImportContractCsvResponse> => {
  try {
    const contract = removeUndefinedProperties(convert(csv)) ?? {};

    if (contract.contract_kind === 0) {
      // PD契約はスキップする
      return { result: 'skip', id: contract.id, httpStatus: undefined };
    }

    const { http: httpStatus, newContract } = await create(contract);

    if (httpStatus.status === HttpStatus.OK || httpStatus.status === HttpStatus.ACCEPTED || httpStatus.status === HttpStatus.CREATED) {
      return { result: 'success', id: newContract?.id, httpStatus }
    }

    return { result: 'failure', id: contract.id, errors: genErrorMessages(newContract), httpStatus };
  } catch (e) {
    const message = e instanceof Error ? e.message : `${e}`
    return { result: 'error', message };
  }
};

const flattenErrors = (errors?: ErrorResponse, prefix: string = ''): ErrorResponse => {
  if (!errors) return {}
  return Object.keys(errors).reduce((acc: ErrorResponse, key: string): ErrorResponse => {
      const newKey = prefix ? `${prefix}.${key}` : key;
      const value = errors[key];
      if (typeof value === 'object' && !Array.isArray(value)) {
        Object.assign(acc, flattenErrors(value, newKey));
      } else if (Array.isArray(value)) {
        acc[newKey] = value;
      } else {
        acc[newKey] = [value];
      }
      return acc;
  }, {});
}

const genErrorMessages = (errors: any): {key: string, values: string[]}[] => {
  const flattenedErrors = flattenErrors(errors);
  return Object.keys(flattenedErrors).map(key => {
    const localizedKey = LocalizedValueLabels[key as CsvColumn] ?? LocalizedValueLabels[`${key}.id` as CsvColumn] ?? key
    const values = typeof flattenedErrors[key] === 'string' ? [flattenedErrors[key]] :  flattenedErrors[key];
    return { key: localizedKey, values };
  });
}

export const localizedKey = (key: CsvColumn): CsvLocalizedColumn => LocalizedValueLabels[key];

export const sequentialPromiseAll = async (promises: (() => Promise<any>)[]) => {
  const first = promises.shift()
  if (!first) {
    return []
  }

  const results: any[] = []
  await promises
    .concat(() => Promise.resolve())
    .reduce(async (prev, next) => {
      const res = await prev
      results.push(res)
      return next()
    }, Promise.resolve(first()))

  return results
}

export const removeUndefinedProperties = (obj: { [key: string]: any }): { [key: string]: any } => {
  const result: { [key: string]: any } = {};

  Object.keys(obj).forEach(key => {
    const value = obj[key];
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      result[key] = removeUndefinedProperties(value);
    } else if (value !== undefined) {
      result[key] = value;
    }
  });

  return result;
}