import { atom } from "recoil";
import { User } from "../models/User";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist();

export const loggedInUserAtom = atom<User | undefined>({
  key: "loggedInUser",
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});