import axios, { AxiosRequestConfig } from "axios";
import { HttpStatus, HttpStatusObject } from "../models/HttpStatus";
import { Tokens } from "../models/Tokens";
import { eventManager } from "../util/eventManager";

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

class Api {
  private ACCESS_TOKEN: string = "accessToken";
  private REFRESH_TOKEN: string = "refreshToken";
  private RECOIL_PERSIST: string = "recoil-persist";

  login = async (username: string, password: string, organization_id?: string): Promise<[HttpStatusObject, Tokens | undefined]> => {
    try {
      const res = await axios.post<Tokens>("/api/auth/jwt/create/", {
        username,
        password,
        organization_id,
      });
      localStorage.setItem(this.ACCESS_TOKEN, res.data.access);
      localStorage.setItem(this.REFRESH_TOKEN, res.data.refresh);
      return [{ status: HttpStatus.OK }, new Tokens(res.data.refresh, res.data.access)];
    } catch (err: any) {
      console.log(err?.response?.status);
      return [this.errorHttpStatusObject(err?.response?.status, err?.response?.data), undefined];
    }
  };

  logout = () => {
    localStorage.removeItem(this.ACCESS_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem(this.RECOIL_PERSIST);
  };

  errorHttpStatusObject = (status: HttpStatus, data: any) => {
    let isStatusAccessed = false;

    const httpStatusObjectProxy = new Proxy<HttpStatusObject>({ status, data }, {
      get: (target, prop) => {
        if (prop === 'status') {
          isStatusAccessed = true;
        }
        return (target as any)[prop];
      }
    });

    if (status >= HttpStatus.INTERNAL_SERVER_ERROR && status <  HttpStatus.UN_FETCHED) {
      eventManager.emit('APIError', data);
    } else if (status !== HttpStatus.UNAUTHORIZED) {
      setTimeout(() => {
        // エラーが発生していて、一定期間 status プロパティにアクセスがない場合は、例外処理を行っていないと判断し、イベントを発生させる
        if (!isStatusAccessed) {
          eventManager.emit('APIError', data);
        }
      }, 1000);
    }
  
    return httpStatusObjectProxy;
  };


  get = async <T>(path: string, contentType: string = "application/json", accept: string = "application/json"): Promise<[HttpStatusObject, T | undefined]> => {
    const token = localStorage.getItem(this.ACCESS_TOKEN);
    try {
      if (token === null) {
        const res = await axios.get<T>(path);
        return [{ status: HttpStatus.OK }, res.data];
      }

      const option: AxiosRequestConfig = {
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type": contentType,
          Accept: accept
        },
      };

      const res = await axios.get<T>(path, option);
      return [{ status: HttpStatus.OK }, res.data];
    } catch (err: any) {
      console.log(err);
      return [this.errorHttpStatusObject(err?.response?.status, err?.response?.data), err?.response?.data];
    }
  };

  getRaw = async <T>(path: string, 
    contentType: string = "application/json",
    accept: string = "application/json"): Promise<[HttpStatusObject, T | undefined]> => {
    const token = localStorage.getItem(this.ACCESS_TOKEN);
    try {
      if (token === null) {
        const res = await axios.get<T>(path);
        return [{ status: HttpStatus.OK }, res.data];
      }

      const option: AxiosRequestConfig = {
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type": contentType,
          Accept: accept,
        },
        transformResponse: (r) => r
      };

      const res = await axios.get<T>(path, option);
      return [{ status: HttpStatus.OK }, res.data];
    } catch (err: any) {
      console.log(err);
      return [this.errorHttpStatusObject(err?.response?.status, err?.response?.data), err?.response?.data];
    }
  };

  getBlob = async (path: string): Promise<[HttpStatusObject, Blob | undefined, string | undefined]> => {
    const token = localStorage.getItem(this.ACCESS_TOKEN);
    try {
      if (token === null) {
        const res = await axios.get<Blob>(path, { responseType: "blob" });
        return [{ status: HttpStatus.OK }, res.data, res.headers["content-type"]];
      }

      const option: AxiosRequestConfig = {
        headers: {
          Authorization: `JWT ${token}`,
        },
        responseType: "blob"
      };

      const res = await axios.get<Blob>(path, option);
      return [{ status: HttpStatus.OK }, res.data, res.headers["content-type"]];
    } catch (err: any) {
      console.log(err);
      return [this.errorHttpStatusObject(err?.response?.status, err?.response?.data), err?.response?.data, undefined];
    }
  };



  post = async <T, R>(path: string, data: T): Promise<[HttpStatusObject, R | undefined]> => {
    const token = localStorage.getItem(this.ACCESS_TOKEN);
    try {
      if (token === null) {
        const res = await axios.post<R>(`${path}`, data, {
          headers: { "Content-Type": "application/json" },
        });
        return [{ status: HttpStatus.OK }, res.data];
      }

      const option: AxiosRequestConfig = {
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post<R>(`${path}`, data, option);
      return [{ status: res.status }, res.data];
    } catch (err: any) {
      console.log(err);
      return [this.errorHttpStatusObject(err?.response?.status, err?.response?.data), err?.response?.data];
    }
  };

  patchFormData = async <R>(path: string, formData: FormData): Promise<[HttpStatusObject, R | undefined]> => {
    const token = localStorage.getItem(this.ACCESS_TOKEN);
    try {
      const res = await axios.patch<R>(path, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...(token ? { Authorization: `JWT ${token}` } : {})
        },
      });
      return [{ status: HttpStatus.OK }, res.data];
    } catch (err: any) {
      console.log(err);
      return [this.errorHttpStatusObject(err?.response?.status, err?.response?.data), err?.response?.data];
    }
  };

  put = async <T, R>(path: string, data: T): Promise<[HttpStatusObject, R | undefined]> => {
    const token = localStorage.getItem(this.ACCESS_TOKEN);
    try {
      if (token === null) {
        const res = await axios.put<R>(`${path}`, data, {
          headers: { "Content-Type": "application/json" },
        });
        return [{ status: HttpStatus.OK }, res.data];
      }

      const option: AxiosRequestConfig = {
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type": "application/json",
        },
      };

      const res = await axios.put<R>(`${path}`, data, option);
      return [{ status: HttpStatus.OK }, res.data];
    } catch (err: any) {
      console.log(err);
      return [this.errorHttpStatusObject(err?.response?.status, err?.response?.data), err?.response?.data];
    }
  };

  patch = async <T, R>(path: string, data: T): Promise<[HttpStatusObject, R | undefined]> => {
    const token = localStorage.getItem(this.ACCESS_TOKEN);
    try {
      if (token === null) {
        const res = await axios.patch<R>(`${path}`, data, {
          headers: { "Content-Type": "application/json" },
        });
        return [{ status: HttpStatus.OK }, res.data];
      }

      const option: AxiosRequestConfig = {
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type": "application/json",
        },
      };

      const res = await axios.patch<R>(`${path}`, data, option);
      return [{ status: HttpStatus.OK }, res.data];
    } catch (err: any) {
      console.log(err);
      return [this.errorHttpStatusObject(err?.response?.status, err?.response?.data), err?.response?.data];
    }
  };

  delete = async <T>(path: string): Promise<[HttpStatusObject, T | undefined]> => {
    const token = localStorage.getItem(this.ACCESS_TOKEN);
    try {
      if (token === null) {
        const res = await axios.delete<T>(path);
        return [{ status: HttpStatus.OK }, res.data];
      }

      const option: AxiosRequestConfig = {
        headers: {
          Authorization: `JWT ${token}`,
        },
      };

      const res = await axios.delete<T>(path, option);
      return [{ status: res.status }, res.data];
    } catch (err: any) {
      console.log(err);
      return [this.errorHttpStatusObject(err?.response?.status, err?.response?.data), err?.response?.data];
    }
  };
}

export const api = new Api();