export const convert_management_state = (str: string | undefined) => {
  switch (str) {
    case "申し込み":
      return 0;
    case "契約中":
      return 1;
    case "解約済み":
      return 2;
    case "解約申請":
      return 3;
    case "更新完了":
      return 4;
    default:
      return undefined;
  }
}

export const convert_contract_kind = (str: string | undefined) => {
  switch (str) {
    case "ParkDirect":
      return 0;
    case "PD代理契約": // 古いCSVのフォーマットにも対応しておく
    case "代理契約":
      return 1;
    case "直接契約": // 古いCSVのフォーマットにも対応しておく
    case "直接契約/直接支払い":
      return 2;
    case "直接契約/PD支払い":
      return 3;
    default:
      return undefined;
  }
}

export const convert_auto_update = (str: string | undefined) => {
  switch (str) {
    case "自動更新":
      return true;
    case "自動更新ではない":
      return false;
    default:
      return undefined;
  }
}

export const convert_rental_item = (str: string | undefined) => {
  switch (str) {
    case "あり":
      return true;
    case "なし":
      return false;
    default:
      return undefined;
  }
}

export const convert_normal_contract = (str: string | undefined) => {
  switch (str) {
    case "通常契約":
      return true;
    case "イレギュラー":
      return false;
    default:
      return undefined;
  }
}

export const convert_contract_period_month = (str: string | undefined) => {
  if (str === undefined) {
    return undefined;
  }

  return parseInt(str.replace("ヶ月", ""), 10);
}

export const convert_payment_method = (str: string | undefined) => {
  switch (str) {
    case "Park Direct":
      return 0;
    case "振込":
      return 1;
    case "口座振替":
      return 2;
    case "クレジットカード":
      return 3
    default:
      return undefined;
  }
}

export const convert_partition_type = (str: string | undefined) => {
  switch (str) {
    case "平置き":
      return 0;
    case "機械式":
      return 1;
    case "立体":
      return 2;
    default:
      return undefined;
  }
}

export const convert_payment_deposit_kind_type = (str: string | undefined) => {
  switch (str) {
    case "現金":
      return 0;
    case "普通":
      return 1;
    case "当座":
      return 2;
    case "その他":
      return 3;
    default:
      return undefined;
  }
}

export const convert_initial_procedure_type = (str: string | undefined) => {
  switch (str) {
    case "顧客":
      return 0;
    case "ニーリー":
      return 1;
    default:
      return undefined;
  }
}

export const convert_lessor_type = (str: string | undefined) => {
  switch (str) {
    case "個人":
      return 0;
    case "法人":
      return 1;
    default:
      return undefined;
  }
}

export const convert_tax_category = (str: string | undefined) => {
  switch (str) {
    case "非課税":
      return 0;
    case "課税":
      return 1;
    default:
      return undefined;
  }
}

export const convert_seasonal_adjustment = (str: string | undefined) => {
  switch (str) {
    case "冬季賃料":
      return 0;
    case "除雪費用":
      return 1;
    case "その他":
      return 2;
    default:
      return undefined;
  }
}
