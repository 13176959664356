import { css } from "@emotion/react"
import React from "react"
import { Link } from "react-router-dom";
import { useApiGet } from "../../hooks/useApiGet";
import { Loading } from "../parts/Loading";
import { faCircleExclamation, IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoginState, useUser } from "../../hooks/useUser";
import { NotificationPanel, CancelsPanel, ContractSummaryPanel, TransitionPanel } from "../parts/Panel";
import { ContractFromApi } from "../../models/contract/ContractFromApi";
import { Grid } from "@mui/material";

const sectionStyle = css({
  margin: "2rem"
});

const titleStyle = css({
  fontFamily: '"Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif',
  fontWeight: "normal",
  fontSize: "1.4rem"
});

const infoArticleStyle = css({
  background: "white",
  padding: "2rem",
  fontSize: "0.8rem",
});

const infoTextStyle = css({
  display: "block",
  marginBottom: "1rem",
  color: "#b0012f",
});

const withIcon = (icon: IconDefinition, text: string | JSX.Element) => {
  return <>
    <FontAwesomeIcon css={css({ marginRight: "0.5rem" })} icon={icon} />{text}
  </>
}


export const HomeComp = () => {
  const [fetchUserState, userState] = useUser();

  const [httpStatusRenew, renewCount] = useApiGet<number>("/api/v1/admin/contract_renew_count/");
  const [httpStatusNullCount, nullCount] = useApiGet<number>("/api/v1/admin/payment_null_count/");
  const [httpStatusUnsetOrganization, unsetOrganizationContract] = useApiGet<ContractFromApi>("/api/v1/contract/?unset_organization=1");

  if (httpStatusRenew === undefined || httpStatusNullCount === undefined || httpStatusUnsetOrganization === undefined || fetchUserState === LoginState.UnFetched) {
    return <Loading />
  }

  return (
    <>
      {
        (userState?.isAdmin && (renewCount !== undefined || nullCount !== undefined || unsetOrganizationContract !== undefined)) &&
        <section css={sectionStyle}>
          <h2 css={titleStyle}>お知らせ</h2>
          <article css={infoArticleStyle}>
            {renewCount !== undefined &&
              <Link css={infoTextStyle} to="/renew-contract-list/1">
                {withIcon(faCircleExclamation, `更新が必要な契約が ${renewCount} 件あああります`)}
              </Link>
            }
            {nullCount !== undefined &&
              <Link to="/null-payment-list/1" css={infoTextStyle}>
                {withIcon(faCircleExclamation, `総額の入力が必要な決済レコードが ${nullCount} 件あります`)}
              </Link>
            }
            {unsetOrganizationContract !== undefined && unsetOrganizationContract.count > 0 &&
              <Link to="/unset-organization-contract-list/1" css={infoTextStyle}>
                {withIcon(faCircleExclamation, `組織の紐付けが必要な契約が ${unsetOrganizationContract.count} 件あります`)}
              </Link>
            }
          </article>
        </section>
      }

      <section css={sectionStyle}>
        <Grid container spacing={2} alignItems="stretch" >
          <Grid item xs={6}>
            <Grid container spacing={2} alignItems="stretch">
              <Grid item xs={12}><NotificationPanel /></Grid>
              <Grid item xs={12}><ContractSummaryPanel /></Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}><TransitionPanel /></Grid>
          <Grid item xs={12}><CancelsPanel /></Grid>
        </Grid>
      </section>
    </>
  )
}
