class EventManager extends EventTarget {
    emit(eventType: EventType, detail = {}) {
        const event = new CustomEvent(eventType, { detail });
        this.dispatchEvent(event);
    }

    on(eventType: EventType, handler: EventListenerOrEventListenerObject | null) {
        this.addEventListener(eventType, handler);
    }

    off(eventType: EventType, handler: EventListenerOrEventListenerObject | null) {
        this.removeEventListener(eventType, handler);
    }
}

export type EventType = 'APIError';

export interface APIErrorEvent extends CustomEvent<{ message: string }>{};

export const eventManager = new EventManager();