import {
  DeepMap,
  FieldError,
  FieldErrors,
  FieldNamesMarkedBoolean,
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

import { Input, InputProps } from "./Input";
import classNames from "classnames";

export declare type FormState<TFieldValues> = {
  isDirty: boolean;
  dirtyFields: FieldNamesMarkedBoolean<TFieldValues>;
  isSubmitted: boolean;
  isSubmitSuccessful: boolean;
  submitCount: number;
  touchedFields: FieldNamesMarkedBoolean<TFieldValues>;
  isSubmitting: boolean;
  isValidating: boolean;
  isValid: boolean;
  errors: FieldErrors<TFieldValues>;
};

export type RhfInputProps<T extends FieldValues> = InputProps &
  UseControllerProps<T>;

/**
 * react-hook-formラッパー
 */
export const RhfInput = <T extends FieldValues>(
  props: RhfInputProps<T>
) => {
  const { name, control, placeholder, className, css, type, errCss } = props;
  const {
    field: { ref, ...rest },
    formState: { errors },
  } = useController<T>({ name, control });

  return (
    <Input
      type={type}
      inputRef={ref}
      css={css}
      errCss={errCss}
      className={classNames(
        //formControlStyles.formInput,
        //formControlStyles.formTextArea,
        className
      )}
      placeholder={placeholder}
      {...rest}
      error={
        errors[name] &&
        `${(errors[name] as DeepMap<FieldValues, FieldError>).message}`
      }
    />
  );
};
