import { css } from "@emotion/react";
import { Button, LinearProgress, Box } from "@mui/material";
import { parse } from "csv/dist/esm/sync";
import React, { RefObject, useCallback, useRef, useState } from "react";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sequentialPromiseAll } from "./utils";
import { HttpStatus } from "../../../models/HttpStatus";
import { AdminCheck } from "../AdminCheck";
import styled from "@emotion/styled";
import { CsvModel, LocalizedValueLabels } from "./forDirect/types";
import { ContractDetail } from "../../../models/contract/ContractDetail";
import { api } from "../../../infra/Api";
import { FixedSizeList, ListChildComponentProps } from "react-window";

export const ImportPDContract = () => {
  const [importedIds, setImportedIds] = useState<Array<string | undefined>>([]);
  const [skipedIds, setSkipedIds] = useState<Array<string | undefined>>([]);
  const [isImportError, setIsImportError] = useState(false);
  const [errorMessages, setErrorMessages] = useState<Array<string | undefined>>([]);
  const [isImporting, setIsImporting] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [csvLines, setCsvLines] = useState(0);

  const fileRef = useRef<HTMLInputElement>(null);

  const importPDContract = async (id: string, file: RefObject<HTMLInputElement>) => {
    const csvs = file.current?.files;
    if (csvs === undefined || csvs === null) {
      return;
    }

    const buffer = Buffer.from(await csvs[0].arrayBuffer())

    // CSVのヘッダのチェック
    const readColumnNames: string[][] = parse(buffer, { bom: true, from_line: 1, to_line: 1 })
    if (readColumnNames.length === 0) {
      setErrorMessages(['CSVが空のため、処理を中断しました。'])
    }

    const checkedColumnNames = Object.values(PDLocalizedValueLabels).reduce<{ [key: string]: boolean }>((obj, columnName) => {
      obj[columnName] = false;
      return obj;
    }, {});

    readColumnNames[0].forEach(key => checkedColumnNames[key] = true);

    const headerErrors: string[] = []
    Object.keys(checkedColumnNames).filter(key => !checkedColumnNames[key]).forEach(columnName => {
      headerErrors.push(columnName)
    })

    if (headerErrors.length > 0) {
      setIsImportError(true);
      setErrorMessages([
        'CSVに以下のカラムが見つからなかったため、処理を中断しました。',
        ...(headerErrors.map(e => `・ ${e}`)),
      ]);
      return;
    }

    // CSVデータの読み込み
    const lines: CsvModel[] = parse(buffer, { bom: true, columns: true });
    setCsvLines(lines.length);

    await sequentialPromiseAll(lines.map((line) => async () => {
      const { id, result, message, httpStatus } = await importPDContractCsv(line);
      if (result === 'success') {
        setImportedIds(prev => [`${httpStatus === HttpStatus.CREATED ? '新規' : '更新'}: ${id}`, ...prev]);
      } else if (result === 'failure') {
        setErrorMessages(prev => [id, ...prev]);
      } else {
        setErrorMessages(prev => [message, ...prev]);
      }
    }))
  }

  const onChangeInputFile = useCallback(() => {
    if ((fileRef?.current?.files?.length || 0) > 0) {
      setSelectedFile(fileRef?.current?.files?.[0]);
    } else {
      setSelectedFile(undefined);
      setCsvLines(0);
    }
  }, []);

  const importPDContracts = useCallback(async () => {
    setIsImporting(true);
    setIsImportError(false);
    setImportedIds([]);
    setSkipedIds([]);
    setErrorMessages([]);
    await importPDContract('', fileRef);
    setIsImporting(false);
  }, [importPDContract]);

  const update = async (contract: PDUpdateContract) => {
    const [http, updatedContract] = await api.patch<PDUpdateContract, ContractDetail>(
      "/api/v1/admin/pd_contract/",
      contract,
    );
    return { http, updatedContract };
  };

  const importPDContractCsv = async (csv: CsvModel): Promise<ImportPDContractCsvResponse> => {
    try {
      const contract = convert(csv);

      const { http: httpStatus, updatedContract } = await update(contract);

      if (httpStatus.status === HttpStatus.OK || httpStatus.status === HttpStatus.ACCEPTED || httpStatus.status === HttpStatus.CREATED) {
        return { result: 'success', id: updatedContract?.id, httpStatus: httpStatus.status }
      }

      return { result: 'failure', id: `${contract.id} - ${JSON.stringify(updatedContract)}`, httpStatus: httpStatus.status };
    } catch (e) {
      const message = e instanceof Error ? e.message : `${e}`
      return { result: 'error', message };
    }
  };

  const ImportedListItem = ({ index, style }: ListChildComponentProps) => (<StyledListItem style={style}>{importedIds[index]}</StyledListItem>);
  const ErrorListItem = ({ index, style }: ListChildComponentProps) => (<StyledListItem style={style}>{errorMessages[index]}</StyledListItem>);

  return (
    <div css={css({ margin: "2rem" })} >
      <AdminCheck />
      <h2>PD契約インポート</h2>
      <div>
        <StyledFileDiv>
          <Button variant={selectedFile ? 'outlined' : 'contained'} component="label" startIcon={<FontAwesomeIcon icon={faFileCsv} />} disabled={isImporting} >
            CSVファイルを選択
            <input type="file" hidden ref={fileRef} accept="text/csv" onChange={onChangeInputFile} />
          </Button>
          <StyledSelectedFileText>{selectedFile?.name}</StyledSelectedFileText>
          <Box sx={{ width: '50%' }} style={{ visibility: csvLines && !isImportError ? 'visible' : 'hidden' }}>
            <LinearProgress variant="determinate" value={csvLines ? ((importedIds.length + skipedIds.length + errorMessages.length) / csvLines) * 100 : 0} />
          </Box>
        </StyledFileDiv>
        <StyledImportButtonDiv style={{ visibility: selectedFile ? 'visible' : 'hidden' }}>
          <Button variant="contained" onClick={importPDContracts} disabled={isImporting}>{isImporting ? '取り込み中...' : '取り込み'}</Button>
        </StyledImportButtonDiv>
      </div>
      <div>
        {importedIds.length > 0 &&
          <>
            <h3>インポートに成功した契約ID</h3>
            <StyledFixedSizeList height={300} width="50%" itemSize={30} itemCount={importedIds.length}>
              {ImportedListItem}
            </StyledFixedSizeList>
          </>
        }
      </div>
      {isImportError ? (
        <div>
          <h3>インポート失敗</h3>
          <StyledFixedSizeList height={300} width="50%" itemSize={30} itemCount={errorMessages.length}>
            {ErrorListItem}
          </StyledFixedSizeList>
        </div>
      ) : errorMessages.length > 0 ? (
        <div>
          <h3>インポートに失敗した契約ID</h3>
          <StyledFixedSizeList height={300} width="50%" itemSize={30} itemCount={errorMessages.length}>
            {ErrorListItem}
          </StyledFixedSizeList>
        </div>
      ) : null}
    </div>
  )
}

const PDLocalizedValueLabels = {
  "id": "契約id",
  "parking_user.employee_number": LocalizedValueLabels["parking_user.employee_number"],
  "parking_user.affiliation_branch_name": LocalizedValueLabels["parking_user.affiliation_branch_name"],
  "parking_user.affiliation_branch_number": LocalizedValueLabels["parking_user.affiliation_branch_number"],
  "parking_user.affiliation_department_name": LocalizedValueLabels["parking_user.affiliation_department_name"],
  "parking_user.affiliation_department_number": LocalizedValueLabels["parking_user.affiliation_department_number"],
  "parking_contractor.employee_number": LocalizedValueLabels["parking_contractor.employee_number"],
  "parking_contractor.affiliation_branch_name": LocalizedValueLabels["parking_contractor.affiliation_branch_name"],
  "parking_contractor.affiliation_branch_number": LocalizedValueLabels["parking_contractor.affiliation_branch_number"],
  "parking_contractor.affiliation_department_name": LocalizedValueLabels["parking_contractor.affiliation_department_name"],
  "parking_contractor.affiliation_department_number": LocalizedValueLabels["parking_contractor.affiliation_department_number"],
  "client_company.invoice_tax_rate": LocalizedValueLabels["client_company.invoice_tax_rate"],
  "client_company.invoice_receiver_name": LocalizedValueLabels["client_company.invoice_receiver_name"],
  "lessor.invoice_tax_rate": LocalizedValueLabels["lessor.invoice_tax_rate"],
  "lessor.invoice_receiver_name": LocalizedValueLabels["lessor.invoice_receiver_name"],
  "broker_company.invoice_tax_rate": LocalizedValueLabels["broker_company.invoice_tax_rate"],
  "broker_company.invoice_receiver_name": LocalizedValueLabels["broker_company.invoice_receiver_name"],
  "contract_attribution.memo1": LocalizedValueLabels["contract_attribution.memo1"],
  "contract_attribution.memo2": LocalizedValueLabels["contract_attribution.memo2"],
  "contract_attribution.memo3": LocalizedValueLabels["contract_attribution.memo3"],
  "contract_attribution.other1": LocalizedValueLabels["contract_attribution.other1"],
  "contract_attribution.other1_memo": LocalizedValueLabels["contract_attribution.other1_memo"],
  "contract_attribution.other2": LocalizedValueLabels["contract_attribution.other2"],
  "contract_attribution.other2_memo": LocalizedValueLabels["contract_attribution.other2_memo"],
  "contract_attribution.other3": LocalizedValueLabels["contract_attribution.other3"],
  "contract_attribution.other3_memo": LocalizedValueLabels["contract_attribution.other3_memo"],
  "contract_attribution.notice_memo1": LocalizedValueLabels["contract_attribution.notice_memo1"],
  "contract_attribution.notice_memo2": LocalizedValueLabels["contract_attribution.notice_memo2"],
  "contract_attribution.notice_memo3": LocalizedValueLabels["contract_attribution.notice_memo3"],
} as const;

type PDCsvColumn = keyof typeof PDLocalizedValueLabels;

type PDCsvLocalizedColumn = typeof PDLocalizedValueLabels[PDCsvColumn];

type PDCsvModel = { [P in PDCsvLocalizedColumn]: string };

type ImportPDContractCsvResponse = {
  result: 'success' | 'failure' | 'error'
  id?: string;
  message?: string;
  httpStatus?: HttpStatus;
};

type PDUpdateContract = {
  "id": string;
  "parking_user": {
      "employee_number": string;
      "affiliation_branch_name":  string;
      "affiliation_branch_number":  string;
      "affiliation_department_name":  string;
      "affiliation_department_number":  string;
  },
  "parking_contractor": {
      "employee_number":  string;
      "affiliation_branch_name": string;
      "affiliation_branch_number":  string;
      "affiliation_department_name":  string;
      "affiliation_department_number":  string;
  },
  "client_company": {
      "invoice_tax_rate":  string;
      "invoice_receiver_name":  string;
  },
  "lessor": {
      "invoice_tax_rate":  string;
      "invoice_receiver_name":  string;
  },
  "broker_company": {
      "invoice_tax_rate":  string;
      "invoice_receiver_name":  string;
  },
  "contract_attribution": {
      "memo1":  string;
      "memo2":  string;
      "memo3":  string;
      "other1":  string;
      "other1_memo": string;
      "other2":  string;
      "other2_memo": string;
      "other3": string;
      "other3_memo":  string;
      "notice_memo1":  string;
      "notice_memo2":  string;
      "notice_memo3":  string;
  }
}

const localizedKey = (key: PDCsvColumn): PDCsvLocalizedColumn => PDLocalizedValueLabels[key];

// CSVデータを契約情報に変換
const convert = (csv: PDCsvModel) => {
  const contract = {
    id: csv[localizedKey('id')],
    // 利用者情報
    parking_user: {
      employee_number: csv[localizedKey('parking_user.employee_number')],
      affiliation_branch_name: csv[localizedKey('parking_user.affiliation_branch_name')],
      affiliation_branch_number: csv[localizedKey('parking_user.affiliation_branch_number')],
      affiliation_department_name: csv[localizedKey('parking_user.affiliation_department_name')],
      affiliation_department_number: csv[localizedKey('parking_user.affiliation_department_number')],
    },
    // 契約者情報
    parking_contractor: {
      employee_number: csv[localizedKey('parking_contractor.employee_number')],
      affiliation_branch_name: csv[localizedKey('parking_contractor.affiliation_branch_name')],
      affiliation_branch_number: csv[localizedKey('parking_contractor.affiliation_branch_number')],
      affiliation_department_name: csv[localizedKey('parking_contractor.affiliation_department_name')],
      affiliation_department_number: csv[localizedKey('parking_contractor.affiliation_department_number')],
    },
    // 管理会社情報
    client_company: {
      invoice_tax_rate: csv[localizedKey('client_company.invoice_tax_rate')],
      invoice_receiver_name: csv[localizedKey('client_company.invoice_receiver_name')],
    },
    // 貸主情報
    lessor:
    {
      invoice_tax_rate: csv[localizedKey('lessor.invoice_tax_rate')],
      invoice_receiver_name: csv[localizedKey('lessor.invoice_receiver_name')],
    },
    // 仲介会社情報
    broker_company:
    {
      invoice_tax_rate: csv[localizedKey('broker_company.invoice_tax_rate')],
      invoice_receiver_name: csv[localizedKey('broker_company.invoice_receiver_name')],
    },
    contract_attribution:
    {
      // 備考
      memo1: csv[localizedKey('contract_attribution.memo1')],
      memo2: csv[localizedKey('contract_attribution.memo2')],
      memo3: csv[localizedKey('contract_attribution.memo3')],
      // 契約条件
      other1: csv[localizedKey('contract_attribution.other1')],
      other1_memo: csv[localizedKey('contract_attribution.other1_memo')],
      other2: csv[localizedKey('contract_attribution.other2')],
      other2_memo: csv[localizedKey('contract_attribution.other2_memo')],
      other3: csv[localizedKey('contract_attribution.other3')],
      other3_memo: csv[localizedKey('contract_attribution.other3_memo')],
      // お知らせ・注意喚起のメモ
      notice_memo1: csv[localizedKey('contract_attribution.notice_memo1')],
      notice_memo2: csv[localizedKey('contract_attribution.notice_memo2')],
      notice_memo3: csv[localizedKey('contract_attribution.notice_memo3')],
    }
  };

  return contract;
};

// Styles

const StyledFixedSizeList = styled(FixedSizeList)`
  background-color: #fcfcfc;
`;

const StyledListItem = styled.div`
  display: flex;
  padding-left: .5em;
  align-items: center;
  white-space: nowrap;
`;

const StyledFileDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledSelectedFileText = styled.div`
  margin-top: .5em;
  font-size: 14px;
  height: 20px;
`;

const StyledImportButtonDiv = styled.div`
  margin-top: 1em;
`;
