import { HttpStatusObject } from "../../../../models/HttpStatus";

export const LocalizedValueLabels = {
    "id": "契約id",
    "organization.id": "組織id",
    "organization.name": "組織名",
    "management_state": "管理ステータス",
    "payment_bank": "支払先口座情報",
    "normal_contract" : "通常契約",
    "contract_kind": "ParkDirect/代理契約/直接契約・直接支払い/直接契約・PD支払い",
    "auto_update": "自動更新",
    "initial_procedure_type": "初回契約手続き区分",
    "client_company.client_company_name": "管理会社名",
    "client_company.client_store_name": "店舗名",
    "client_company.parking_name": "駐車場名",
    "client_company.partition": "区画番号",
    "client_company.partition_type": "区画種別",
    "client_company.address": "駐車場住所",
    "client_company.latitude": "緯度",
    "client_company.longitude": "経度",
    "rental_item": "貸与物",
    "rental_item_contents": "貸与物の内容",
    "client_company.client_company_postal_code": "管理会社郵便番号",
    "client_company.client_company_address": "管理会社住所",
    "client_company.phone_number": "管理会社電話番号",
    "client_company.fax_number": "管理会社FAX番号",
    "client_company.staff_name": "担当者名",
    "client_company.staff_email": "担当者メールアドレス",
    "client_company.invoice_name_or_number": "（管理会社）インボイス発行事業者の氏名または名称および登録番号",
    "client_company.invoice_tax_rate": "（管理会社）適用税率",
    "client_company.invoice_receiver_name": "（管理会社）書類の交付を受ける事業者の氏名または名称",
    "client_company.guarantor_company_name": "保証会社名",
    "client_company.guarantor_invoice_number": "保証会社インボイス登録番号",
    "client_company.note": "（管理会社）備考",

    "lessor.lessor_name": "貸主名",
    "lessor.lessor_type": "貸主区分",
    "lessor.postal_code": "貸主郵便番号",
    "lessor.address": "貸主住所",
    "lessor.phone_number": "貸主電話番号",
    "lessor.fax_number": "貸主FAX番号",
    "lessor.email": "貸主メールアドレス",
    "lessor.invoice_name_or_number": "（貸主）インボイス発行事業者の氏名または名称および登録番号",
    "lessor.invoice_tax_rate": "（貸主）適用税率",
    "lessor.invoice_receiver_name": "（貸主）書類の交付を受ける事業者の氏名または名称",
    "lessor.note": "（貸主）備考",

    "broker_company.broker_company_name": "仲介会社名",
    "broker_company.postal_code": "仲介会社郵便番号",
    "broker_company.address": "仲介会社住所",
    "broker_company.phone_number": "仲介会社電話番号",
    "broker_company.fax_number": "仲介会社FAX番号",
    "broker_company.email": "仲介会社メールアドレス",
    "broker_company.invoice_name_or_number": "（仲介会社）インボイス発行事業者の氏名または名称および登録番号",
    "broker_company.invoice_tax_rate": "（仲介会社）適用税率",
    "broker_company.invoice_receiver_name": "（仲介会社）書類の交付を受ける事業者の氏名または名称",
    "broker_company.note": "（仲介会社）備考",

    "contract_condition.tax_category": "税区分",
    "contract_condition.administrative_fee_with_tax": "事務手数料（税込）",
    "contract_condition.brokerage_fee_with_tax": "仲介手数料(税込)",
    "contract_condition.cancel_date": "解約日",
    "contract_condition.contract_period_month": "契約期間",
    "contract_condition.contracted_date": "契約日",
    "contract_condition.deposit_with_tax": "敷金（非課税）",
    "contract_condition.desired_cancellation_date": "解約希望日",
    "contract_condition.final_month_guarantee_and_management_fee_with_tax": "最終月月額保証管理料（税込）",
    "contract_condition.final_month_rent_with_tax": "最終月賃料（税込）",
    "contract_condition.final_month_rent_without_tax": "最終月賃料（税抜）",
    "contract_condition.first_month_guarantee_and_management_fee_with_tax": "初月月額保証/管理料（税込）",
    "contract_condition.first_month_rent_with_tax": "初月賃料（税込）",
    "contract_condition.first_month_rent_without_tax": "初月賃料（税抜）",
    "contract_condition.first_start_date": "初回契約開始日",
    "contract_condition.initial_guarantee_and_management_fee_with_tax": "初回保証／管理料（税込）",
    "contract_condition.issue_paper_fee_with_tax": "保管場所使用承諾証明書発行料（税込）",
    "contract_condition.key_money_with_tax": "礼金（税込）",
    "contract_condition.monthly_guarantee_and_management_fee_with_tax": "月額保証／管理料（税込）",
    "contract_condition.need_issue_paper": "保管場所使用承諾証明書",
    "contract_condition.issue_date": "保管場所使用承諾証明書の発行月",
    "contract_condition.payment_date": "毎月支払い日",
    "contract_condition.initial_payment_date": "初回支払日",
    "contract_condition.payment_method": "支払先",
    "contract_condition.rent_with_tax": "賃料(税込)",
    "contract_condition.rent_without_tax": "賃料（税抜）",
    "contract_condition.start_date": "賃貸借期間_開始",
    "contract_condition.end_date": "賃貸借期間_終了",
    "contract_condition.update_base_fee_with_tax": "更新料(税込)",
    "contract_condition.update_management_fee_with_tax": "更新事務手数料(税込)",
    "contract_condition.renewal_management_fee_with_tax": "管理更新料(税込)",
    "contract_condition.seasonal_adjustment": "季節調整",
    "contract_condition.seasonal_rent_from": "季節賃料FROM",
    "contract_condition.seasonal_rent_to": "季節賃料TO",
    "contract_condition.seasonal_fee_with_tax": "季節費用(税込)",
    "contract_condition.prorated_rent_refund": "日割賃料返金(税込)",
    "contract_condition.security_deposit_refund": "敷金返金(非課税)",
    "contract_condition.initial_guarantee_fee_without_tax": "初回保証料(非課税)",
    "contract_condition.monthly_guarantee_fee_without_tax": "月額保証料(非課税)",

    "contract_vehicle.car_model": "車種",
    "contract_vehicle.regno_class_digit": "車種-登録番号（分類番号3桁）",
    "contract_vehicle.regno_hiragana": "車種-登録番号（平仮名等）",
    "contract_vehicle.regno_region_name": "車種-登録番号（地域名）",
    "contract_vehicle.regno_seq_digit": "車種-登録番号(一連指定番号4桁)",

    "parking_contractor.affiliation_branch_name": "契約者所属拠点名",
    "parking_contractor.affiliation_branch_number": "契約者所属拠点番号",
    "parking_contractor.affiliation_department_name": "契約者所属組織名",
    "parking_contractor.affiliation_department_number": "契約者所属組織番号",
    "parking_contractor.company_phone_number": "契約者会社電話番号",
    "parking_contractor.email": "契約者メールアドレス",
    "parking_contractor.employee_number": "契約者従業員番号",
    "parking_contractor.name": "契約者名",
    "parking_contractor.name_kana": "契約者名(カナ)",
    "parking_contractor.manager": "契約-担当者名",
    "parking_contractor.phone_number": "契約者携帯電話番号",

    "parking_user.affiliation_branch_name": "利用者所属拠点名",
    "parking_user.affiliation_branch_number": "利用者所属拠点番号",
    "parking_user.affiliation_department_name": "利用者所属組織名",
    "parking_user.affiliation_department_number": "利用者所属組織番号",
    "parking_user.email": "利用者メールアドレス",
    "parking_user.employee_number": "利用者従業員番号",
    "parking_user.name": "利用者名",
    "parking_user.name_kana": "利用者名(カナ)",
    "parking_user.manager": "利用-担当者名",
    "parking_user.mobile_phone_number": "利用者携帯電話番号",
    "parking_user.phone_number": "利用者自宅電話番号",

    "contract_attribution.memo1": "メモ1",
    "contract_attribution.memo2": "メモ2",
    "contract_attribution.memo3": "メモ3",
    "contract_attribution.other1": "その他1",
    "contract_attribution.other1_memo": "その他1_メモ",
    "contract_attribution.other2": "その他2",
    "contract_attribution.other2_memo": "その他2_メモ",
    "contract_attribution.other3": "その他3",
    "contract_attribution.other3_memo": "その他3_メモ",
    "contract_attribution.usage": "使用用途",
    "contract_attribution.notice_memo1": "お知らせ・注意喚起のメモ1",
    "contract_attribution.notice_memo2": "お知らせ・注意喚起のメモ2",
    "contract_attribution.notice_memo3": "お知らせ・注意喚起のメモ3",

    "contract_attribution.init_payment_company_name": "初期費用支払-法人名",
    "contract_attribution.init_payment_send_money_date": "初期費用支払-送金日",
    "contract_attribution.init_payment_bank_number": "初期費用支払-銀行番号",
    "contract_attribution.init_payment_bank_name": "初期費用支払-銀行名",
    "contract_attribution.init_payment_branch_store_number": "初期費用支払-支店番号",
    "contract_attribution.init_payment_branch_name": "初期費用支払-支店名",
    "contract_attribution.init_payment_deposit_kind_type": "初期費用支払-預金種目",
    "contract_attribution.init_payment_bank_account_number": "初期費用支払-口座番号",
    "contract_attribution.init_payment_bank_account_name": "初期費用支払-口座名",
    "contract_attribution.init_payment_send_money_amount": "初期費用支払-送金金額",
    "contract_attribution.monthly_payment_company_name": "毎月支払-法人名",
    "contract_attribution.monthly_payment_send_money_date": "毎月支払-送金日",
    "contract_attribution.monthly_payment_bank_number": "毎月支払-銀行番号",
    "contract_attribution.monthly_payment_bank_name": "毎月支払-銀行名",
    "contract_attribution.monthly_payment_branch_store_number": "毎月支払-支店番号",
    "contract_attribution.monthly_payment_branch_name": "毎月支払-支店名",
    "contract_attribution.monthly_payment_deposit_kind_type": "毎月支払-預金種目",
    "contract_attribution.monthly_payment_bank_account_number": "毎月支払-口座番号",
    "contract_attribution.monthly_payment_bank_account_name": "毎月支払-口座名",
    "contract_attribution.monthly_payment_send_money_amount": "毎月支払-送金金額",

    "for_payment_update_first_month": "初月支払い総額",
    "for_payment_update_normal_month": "通常支払い総額",
    "for_payment_update_cancel_month": "解約月支払い総額",
} as const;

export type CsvColumn = keyof typeof LocalizedValueLabels;
export type CsvLocalizedColumn = typeof LocalizedValueLabels[CsvColumn];
export type CsvModel = { [P in CsvLocalizedColumn]: string };

export type ImportContractCsvResponse = {
    result: 'success' | 'failure' | 'skip' | 'error'
    id?: string;
    message?: string;
    httpStatus?: HttpStatusObject;
    errors?: { key: string, values: string[]}[]
};
